// app/javascript/controllers/tree_controller.js
import { Controller } from "@hotwired/stimulus";
import * as d3 from "d3";

export default class extends Controller {
  static values = {
    nodeWidth: { type: Number, default: 200 },
    nodeHeight: { type: Number, default: 400 },
    verticalSpacing: { type: Number, default: 240 },
    horizontalSpacing: { type: Number, default: 80 },
    root: Object
  };

  async connect() {
    try {
      const rawData = this.rootValue;
      await this.drawTree(rawData);
    } catch (error) {
      console.error("Tree rendering failed:", error);
      this.showError();
    }
  }

  async drawTree(rawData) {
    // 1. Process nodes with measurements
    const nodes = await Promise.all(
        rawData.nodes.map(async n => ({
          ...n.data,
          id: n.data.id,
          html: n.data.html,
          width: await this.measureNode(n.data.html, 'width'),
          height: await this.measureNode(n.data.html, 'height')
        }))
    );

    // 2. Build hierarchy
    const root = this.buildHierarchy(rawData.edges, nodes);

    // 3. Create tree layout
    const tree = d3.tree()
        .nodeSize([this.horizontalSpacingValue, this.verticalSpacingValue])
        .separation((a, b) => {
          const nodeA = nodes.find(n => n.id === a.data.id);
          const nodeB = nodes.find(n => n.id === b.data.id);
          return 1 + Math.max(nodeA?.width || 0, nodeB?.width || 0) / this.horizontalSpacingValue;
        });

    tree(root);

    // 4. Calculate dimensions
    const { width, height, xOffset, yOffset } = this.calculateDimensions(root);

    // 5. Create container
    const svg = d3.select(this.element)
        .html("")
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .style("overflow", "visible");

    // 6. Draw links
    svg.append("g")
        .selectAll("path")
        .data(root.links())
        .join("path")
        .attr("d", d3.linkHorizontal()
            .x(d => d.x + xOffset)
            .y(d => d.y + yOffset))
        .attr("class", "tree-link");

    // 7. Draw nodes
    svg.append("g")
        .selectAll("foreignObject")
        .data(root.descendants())
        .join("foreignObject")
        .attr("x", d => d.x + xOffset - (nodes.find(n => n.id === d.data.id)?.width || this.nodeWidthValue)/2)
        .attr("y", d => d.y + yOffset - (nodes.find(n => n.id === d.data.id)?.height || this.nodeHeightValue)/2)
        .attr("width", d => nodes.find(n => n.id === d.data.id)?.width || this.nodeWidthValue)
        .attr("height", d => nodes.find(n => n.id === d.data.id)?.height || this.nodeHeightValue)
        .html(d => d.data.html)
        .attr("class", "tree-node");

    // 8. Enable scrolling
    this.element.style.width = `${width}px`;
    this.element.style.height = `${height}px`;
    this.element.style.overflow = "auto";
  }

  buildHierarchy(edges, nodes) {
    const rootId = edges.find(e => !edges.some(e2 => e2.data.target === e.data.source))?.data.source;
    const rootNode = nodes.find(n => n.id === rootId);

    if (!rootNode) throw new Error("Root node not found");

    return d3.hierarchy(rootNode, d =>
        edges
            .filter(e => e.data.source === d.id)
            .map(e => nodes.find(n => n.id === e.data.target))
    );
  }

  calculateDimensions(root) {
    let minX = Infinity, maxX = -Infinity;
    let minY = Infinity, maxY = -Infinity;

    root.each(d => {
      if (Number.isFinite(d.x)) {
        minX = Math.min(minX, d.x);
        maxX = Math.max(maxX, d.x);
      }
      if (Number.isFinite(d.y)) {
        minY = Math.min(minY, d.y);
        maxY = Math.max(maxY, d.y);
      }
    });

    const xOffset = -minX + 50;
    const yOffset = -minY + 50;

    return {
      width: Math.max(maxX - minX + 300, 1000),
      height: Math.max(maxY - minY + 200, 800),
      xOffset,
      yOffset
    };
  }

  async measureNode(html, dimension) {
    return new Promise(resolve => {
      const div = document.createElement("div");
      div.style.cssText = `
        position: absolute;
        visibility: hidden;
        display: inline-block;
        max-width: 400px;
        padding: 10px;
      `;
      div.innerHTML = html;
      document.body.appendChild(div);

      requestAnimationFrame(() => {
        const size = dimension === 'width' ?
            div.offsetWidth + 20 :  // width + padding
            div.offsetHeight + 20;  // height + padding

        div.remove();
        resolve(Math.min(size, dimension === 'width' ? 400 : 200));
      });
    });
  }

  showError() {
    this.element.innerHTML = `
      <div class="tree-error">
        Error rendering tree. Please check:
        <ul>
          <li>Root node exists</li>
          <li>Edges connect valid nodes</li>
          <li>Node IDs are unique</li>
        </ul>
      </div>
    `;
  }

  disconnect() {
    this.element.innerHTML = "";
  }
}