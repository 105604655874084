import { Controller } from "@hotwired/stimulus";
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre";
import nodeHtmlLabel from "cytoscape-node-html-label";

cytoscape.use(nodeHtmlLabel);
cytoscape.use(dagre);

export default class extends Controller {
  static values = {root: Object};

  connect() {
    // Initialize Cytoscape with extensions
    cytoscape.use(nodeHtmlLabel);


    const cy = cytoscape({
      container: this.element,
      style: [
        {
          selector: "node",
          style: {
            width: 450,
            height: 260,
            shape: "rectangle",
            "background-color": "#fff",
            "border-width": 1, // Optional: Add a border
            "border-color": "#999",
            "text-valign": "center",
            "text-halign": "center",
          },
        },
        {
          selector: "edge",
          style: {
            width: 2,
            "line-color": "#999",
            "target-arrow-color": "#999",
            "target-arrow-shape": "triangle",
            "curve-style": "bezier",
          },
        },
      ],
    });

    // Add nodes and edges to the graph
    const graphData = this.rootValue;
    cy.add(graphData.nodes);
    cy.add(graphData.edges);

    // Use cytoscape-node-html-label to render HTML inside nodes
    cy.nodeHtmlLabel([
      {
        query: "node",
        halign: "center",
        valign: "center",
        halignBox: "center",
        valignBox: "center",
        tpl(data) {
          return data.html;
        },
      },
    ], {
      enablePointerEvents: true
    });

    cy.ready(() => {
      const canvas = cy.container().querySelector("canvas");
      if (canvas) {
        canvas.style.pointerEvents = "none";
      }
    });

    // Use Dagre layout for an inverted tree structure
    const layout = cy.layout({
      name: "dagre",
      rankDir: "TB", // Top-to-Bottom layout (inverted tree)
      animate: true,
      animationDuration: 1000,
      animationEasing: "ease-in-out",
      spacingFactor: 1.5,
    });

    layout.run();

    // Fit the graph to the container
    cy.fit();

    // Handle window resizing to ensure the graph stays responsive
    window.addEventListener("resize", () => {
      cy.fit();
    });
  }
}

