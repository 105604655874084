import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static values = {
        args: Object,
        url: String
    }

    connect() {
        this.setup();
    }

    setup() {
        var urlQueryParams = new URLSearchParams();
        if (Object.keys(this.argsValue).length > 0) {
            Object.entries(this.argsValue).forEach(([key, value]) => {
                if (value) {
                    if (Array.isArray(value)) {
                        value.forEach((item) => urlQueryParams.append(key, item));
                    } else {
                        urlQueryParams.append(key, value);
                    }
                }
            });
        }

        let url = this.urlValue;
        if(urlQueryParams.toString() !== ''){
            url = `${url}?${urlQueryParams.toString()}`
        }
        $(this.element).select2({
            placeholder: 'Select',
            allowClear: true,
            ajax: {
                url: () => url,
                cache: false,
                dataType: 'json',
                processResults: function (data) {
                    // Transforms the top-level key of the response object from 'items' to 'results'
                    return {
                        results: data.results
                    };
                }

            }
        });
    }

    teardown(){
        $(this.element).select2('destroy');
    }
}