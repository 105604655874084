import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="active-link"
export default class extends Controller {
  static targets = ["link"]

  connect() {
    this.updateActiveLinks()
    window.addEventListener("turbo:load", this.updateActiveLinks.bind(this))
  }

  updateActiveLinks() {
    const currentPath = window.location.pathname
    this.linkTargets.forEach(link => {
      const path = link.getAttribute("href")
      const samePath = currentPath === path
      link.classList.toggle("active_nav", samePath)
      link.classList.toggle("shadow-inner", samePath)
    })
  }
}
