import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cash-in-hand"
export default class extends Controller {
  static targets = ["depositFields", "heading", "totalAmount", "journal", "overlay"];
  static values = {mode: Array}

  connect() {
    this.handleSelection("deposit");
  }

  optionSelected(event) {
    const selectedValue = event.target.value;
    this.handleSelection(selectedValue);
  }

  handleSelection(selectedValue) {

    if (selectedValue === "deposit") {
      this.headingTarget.innerText = "Deposit";
      this.depositFieldsTarget.style.display = "block";
    } else {
      this.headingTarget.innerText = "Transfer";
      this.depositFieldsTarget.style.display = "none";
    }

  }

  handleLineSelection(event) {

    const checkbox = event.target
    const selectedMode = event.params.mode

    if (checkbox.checked) {
      const selectedSet = new Set(this.modeValue)
      const existingMode = selectedSet.values().next().value
      selectedSet.add(selectedMode)

      if (existingMode == null) {
        this.modeValue = [selectedMode]
      } else {
        if (existingMode !== selectedMode) {
          event.target.checked = false;
          alert("You can't select from more than one mode")
        } else if ((existingMode !== 'cash') && (selectedSet.size > 0)) {
          event.target.checked = false;
          alert("You can't select more than one line of this mode")
        } else {
          this.modeValue = [...this.modeValue, selectedMode]
        }

      }

    } else {
      const index = this.modeValue.indexOf(selectedMode);
      const values = [...this.modeValue]

      if (index !== -1) {

        values.splice(index, 1);
        this.modeValue = values
      }
    }



  }

  updateTotalAmount() {
    const total = this.journalTargets.reduce(
      (sum, line) => {
        const childController = this.application.getControllerForElementAndIdentifier(line, "cash-in-hand-line");
        sum += childController.amount
        return sum
      },
      0
    );
    this.totalAmountTarget.textContent = total.toFixed(2);
  }


}
