import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["leadSourceId", "reference_from", "department", "referrerDoctorId",
        "referrerClinicianId", "referrerPatientId", "sales_executive", "referredBy",
        "referrerName", "clinician_arg", "doctor_arg", "sales_executive_arg", "patient_arg"];

    connect() {

        console.log("referrer source form loaded");

        this.lead_source = $(this.leadSourceIdTarget);
        this.reference_from = $(this.reference_fromTarget);
        this.department = $(this.departmentTarget);
        this.referrerDoctorId = $(this.referrerDoctorIdTarget);
        this.referrerClinicianId = $(this.referrerClinicianIdTarget);
        this.referrerPatientId = $(this.referrerPatientIdTarget);
        this.sales_executive = $(this.sales_executiveTarget);
        this.referredBy = $(this.referredByTarget);
        this.referrerName = $(this.referrerNameTarget);

        this.hideAll();

        this.handleSourceChange();
        this.lead_source.on('change', (e) => {
            this.hideAll();
            this.handleSourceChange();
            // Set referrer id to all the user search elements
            [this.patient_argTarget, this.clinician_argTarget, this.doctor_argTarget, this.sales_executive_argTarget]
                .forEach((elem) => {
                    this.setQueryParam(elem, 'referrer_id', this.lead_source.val());
                });
        });

        this.reference_from.on('change', (e) => {
            this.setQueryParam(this.doctor_argTarget, 'type', this.reference_from.find($('select')).val());
            this.handleSourceChange();
        });
    }

    handleSourceChange() {
        const lead_source_id = this.lead_source.val();
        if(lead_source_id){
            if(Number(lead_source_id)=== 12){
                this.setQueryParam(this.doctor_argTarget, 'type', 'external_doctor');
            }
            this.showFieldsForReferrer(this.lead_source.val(), this.reference_from.find($('select')).val());
        }
    }

    showFormFields(elem) {
        elem.find($('textarea')).prop('disabled', false);
        elem.find('input,select').prop('disabled', false);
        elem.find($('select')).material_select();
        elem.show();
    }

    hideFormFields(elem) {
        elem.find('input,select').val(null).trigger('change');

        elem.find('input,select').val('').prop('disabled', true);
        elem.find($('textarea')).val('').prop('disabled', true);
        elem.hide();
    }

    hideAll(){
        [this.department, this.reference_from, this.referrerDoctorId, this.referrerClinicianId,
            this.referrerPatientId, this.sales_executive, this.referredBy, this.referrerName]
            .forEach((elem) => {
                this.hideFormFields(elem);
            });
    }

    showFieldsForReferrer(lead_source_id, reference_from) {
        let url = '/referrers/'+ lead_source_id + "/source_detail_fields";

        if(reference_from !== 'undefined' && reference_from !== ''){
            url += "?reference_from=" + reference_from
        }

        fetch(url, {
            method: 'GET',
            credentials: "same-origin"
        }).then(
            (response) => response.json()
        ).then((json) => {
            const {show_fields, hide_fields} = json;
            show_fields.forEach((option) => {
                this.showFormFields(this[option])
            });
            hide_fields.forEach((option) => {
                this.hideFormFields(this[option])
            });
        }).catch((err) => console.log(err)) ;
    }

    setQueryParam(elem, query_key, val){
        if(val !== undefined){
            if(elem.dataset.controller !== undefined){
                const itemController = this.application.getControllerForElementAndIdentifier(
                    elem,
                    elem.dataset.controller
                );
                itemController.teardown();

                itemController.argsValue = {
                    ...itemController.argsValue, // Preserve existing keys and values
                    [query_key]: val, // Add or update the new key-value pair
                };
                itemController.setup();
            }else{
                console.log('No controller defined for child element')
            }
        }
    }

}
