import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cash-in-hand-line"
export default class extends Controller {
  static targets = ["checkbox", "amount"];
  static values = { id: String, mode: String };

  connect() {
    this.updateAmountState();
  }

  toggle() {
    this.updateAmountState();
    this.dispatch("amountChanged");
  }

  validateAndToggle(event) {

    const available = event.params.available
    const mode = event.params.mode
    const inputted = event.target.value

    if (inputted > available) {
      alert("You can't enter more than what is available to move")
      event.target.value = available;
    }

    if ((mode !== 'cash') && (inputted != available)) {
      alert("For non-cash modes, you have to move the entire amount")
      event.target.value = available;
    }


  }

  updateAmountState() {
    this.amountTarget.disabled = !this.checkboxTarget.checked;
  }

  get isSelected() {
    return this.checkboxTarget.checked;
  }

  get amount() {
    return this.isSelected ? parseFloat(this.amountTarget.value) || 0 : 0;
  }

  get mode() {
    return this.isSelected ? this.modeValue : null;
  }
}
