import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="overlay"
export default class extends Controller {

  static targets = ["overlay"];
  connect() {
  }

  showOverlay() {
    this.overlayTarget.classList.remove("hidden");
  }

  hideOverlay() {
    this.overlayTarget.classList.add("hidden");
  }
}
